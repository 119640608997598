<template>
  <section class="invoice-add-wrapper">
    <b-row class="invoice-add">

      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="12"
        md="12"
      >
        <b-card
          no-body
        >
          <b-card-body>

            <validation-observer ref="validationRules">
              <b-form
                @submit.prevent="onSubmit"
                @reset.prevent="resetForm"
              >
                <b-row>
                  <!-- Company Name -->
                  <b-col md="6">
                    <b-form-group
                      :label="$t('COMPANY_NAME')"
                      label-for="id-companyname"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('COMPANY_NAME')"
                        rules="required"
                      >
                        <b-form-input
                          id="id-companyname"
                          v-model="company.name"
                          maxlength="50"
                          :placeholder="$t('COMPANY_NAME')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Address Line 1 -->
                  <b-col md="6">
                    <b-form-group
                      :label="$t('ADDRESS_LINE_1')"
                      label-for="id-addressLine-1"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('ADDRESS_LINE_1')"
                        rules="required"
                      >
                        <b-form-input
                          id="id-addressLine-1"
                          v-model="company.addressLine1"
                          maxlength="45"
                          :placeholder="$t('ADDRESS_LINE_1')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Address Line 2 -->
                  <b-col md="6">
                    <b-form-group
                      :label="$t('ADDRESS_LINE_2')"
                      label-for="id-addressLine-1"
                    >
                      <b-form-input
                        id="id-addressLine-2"
                        v-model="company.addressLine2"
                        maxlength="45"
                        :placeholder="$t('ADDRESS_LINE_2')"
                      />
                    </b-form-group>
                  </b-col>

                  <!-- Post Code -->
                  <b-col md="6">
                    <b-form-group
                      :label="$t('POST_CODE')"
                      label-for="id-postcode"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('POST_CODE')"
                        rules="required"
                      >
                        <b-form-input
                          id="id-postcode"
                          v-model="company.postcode"
                          maxlength="45"
                          :placeholder="$t('POST_CODE')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- City -->
                  <b-col md="6">
                    <b-form-group
                      :label="$t('CITY')"
                      label-for="id-city"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('CITY')"
                        rules="required"
                      >
                        <b-form-input
                          id="id-city"
                          v-model="company.city"
                          maxlength="45"
                          :placeholder="$t('CITY')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Country -->
                  <b-col md="6">
                    <b-form-group
                      :label="$t('COUNTRY')"
                      label-for="id-country"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('COUNTRY')"
                        rules="required"
                      >
                        <b-form-input
                          id="id-country"
                          v-model="company.country"
                          maxlength="45"
                          :placeholder="$t('COUNTRY')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Contacted Email -->
                  <b-col md="6">
                    <b-form-group
                      :label="$t('CONTACTED_EMAIL')"
                      label-for="id-contactedEmail"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('CONTACTED_EMAIL')"
                        rules="required"
                      >
                        <b-form-input
                          id="id-contactedEmail"
                          v-model="company.email"
                          maxlength="45"
                          :placeholder="$t('CONTACTED_EMAIL')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Pricing plans -->
                  <b-col
                    md="6"
                  >
                    <b-form-group
                      :label="$t('PRICING_PLANS')"
                      label-for="id-package"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('PRICING_PLANS')"
                        rules="required"
                      >
                        <v-select
                          v-model="pricePackage"
                          :options="packageOptions"
                          label="name"
                          :clearable="false"
                          input-id="id-package"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Email notification -->
                  <b-col
                    cols="12"
                  >
                    <b-form-group>
                      <b-form-checkbox
                        id="checkbox-emailNotificationEnabled"
                        v-model="company.emailNotificationEnabled"
                        name="checkbox-emailNotificationEnabled"
                        class="mt-2"
                      >
                        {{ $t('EMAIL_NOTIFICATIONS_COMPANY_USERS') }}
                      </b-form-checkbox>
                    </b-form-group>

                  </b-col>
                  <!-- Submit and reset -->
                  <b-col md="12">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      variant="primary"
                      class="mr-1"
                    >
                      {{ company.id ? $t('BUTTON.UPDATE') : $t('BUTTON.ADD') }}
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      type="reset"
                      variant="outline-secondary"
                    >
                      {{ $t('BUTTON.RESET') }}
                    </b-button>
                  </b-col>

                </b-row>
              </b-form>
            </validation-observer>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import { mapActions } from 'vuex'
import constants from '@/constants/static.json'
import mixinAlert from '@/constants/mixinAlert'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
// eslint-disable-next-line no-unused-vars

export default {
  directives: {
    Ripple,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BFormCheckbox,
  },
  data() {
    return {
      company: {
        id: 0,
        emailNotificationEnabled: false,
        name: '',
        addressLine1: '',
        addressLine2: '',
        postcode: '',
        city: '',
        state: '',
        country: '',
        email: '',
        packageId: '',
      },
      required,
      pricePackage: constants.PACKAGES[0],
      packageOptions: constants.PACKAGES,
    }
  },
  created() {
    if (router.currentRoute.params.id) {
      this.fetchCompany(router.currentRoute.params.id)
    }
  },
  setup() {
    const {
      successMessage,
      showErrorMessage,
    } = mixinAlert()

    return {
      successMessage,
      showErrorMessage,
    }
  },
  methods: {
    ...mapActions('company', [
      'fetchCompanyById',
      'createCompany',
      'updateCompany',
    ]),
    onSubmit() {
      this.$refs.validationRules.validate().then(success => {
        if (success) {
          this.company.packageId = this.pricePackage.id
          if (this.company.id) {
            this.updateCompany(this.company)
              .then(response => {
                if (response) {
                  this.successMessage(this.$i18n.t('MESSAGE.COMPANY_UPDATED'))
                  this.$router.push({ name: 'company-list' })
                }
              })
              .catch(() => {
                this.showErrorMessage()
              })
          } else {
            this.createCompany(this.company)
              .then(response => {
                if (response) {
                  this.successMessage(this.$i18n.t('MESSAGE.COMPANY_CREATED'))
                  this.$router.push({ name: 'company-list' })
                }
              })
              .catch(() => {
                this.showErrorMessage()
              })
          }
        }
      })
    },
    fetchCompany(id) {
      if (id) {
        this.fetchCompanyById(id).then(response => {
          if (response) {
            this.company = response.data
            if (this.company) {
              this.pricePackage = constants.PACKAGES.find(i => i.id === this.company.packageId)
            }
          }
        })
      }
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
      color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '~@core/scss/base/components/variables-dark';

.form-item-section {
background-color:$product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: .35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }

  }
}
</style>
